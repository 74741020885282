import Page from "../components/page";
import PropTypes from "prop-types";
import React from "react";
import SEO from "../components/seo";
import ServiceCard from "../components/service-card";
import { graphql } from "gatsby";

const AanbodPage = ({ data }) => {
  let delay = 0;
  const serviceCards = data.services.edges.map(service => {
    delay += 50;
    return (
      <div
        key={service.node.id}
        className='mb-10 sm:mb-0 sm:px-2 md:px-3 sm:py-3 lg:py-6 sm:w-1/2 lg:w-1/3 xl:w-1/4'
        data-aos='fade-up'
        data-aos-delay={delay}
      >
        <ServiceCard
          image={service.node.image.fluid.src}
          path={`/aanbod/${service.node.name}`.toLowerCase().replace(/ /g, "-")}
          title={service.node.name}
        />
      </div>
    );
  });

  return (
    <Page>
      <SEO title='Aanbod' />
      <div className='p-8 mb-8 lg:mb-20'>
        <div className='container sm:flex sm:flex-wrap'>{serviceCards}</div>
      </div>
    </Page>
  );
};

AanbodPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AanbodPage;

export const pageQuery = graphql`
  query {
    services: allContentfulService(sort: { fields: [createdAt], order: ASC }) {
      edges {
        node {
          id
          name
          image {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;
